var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-card-title',{staticClass:"d-flex align-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10","sm":"11"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","icon":"","label":_vm.$t('generics.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"2","sm":"1"}},[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-refresh')},on:{"click":_vm.loadData}})],1)],1)],1),_c('v-data-table',{staticClass:"app-table",attrs:{"headers":_vm.headers,"items":_vm.productsByTime,"loading":_vm.loading,"items-per-page":20,"search":_vm.search,"footer-props":{ 'items-per-page-options': [100, 30, 50, -1] }},on:{"click:row":_vm.openInfo},scopedSlots:_vm._u([{key:"item.delivery.adjustedStartHour",fn:function(ref){
var item = ref.item;
return [_c('h3',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.delivery.pickUpTime || item.delivery.adjustedStartHour)+" ")])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('image-show',{attrs:{"src":("/uploads/" + (item.product.image))}})],1)]}},{key:"item.product.title",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"fill-height align-center"},[_c('h3',[_vm._v(_vm._s(item.product.title))])])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(item.quantity))])]}},{key:"item.product.info",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"secondary--text",attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-center"},[(item.product.info)?_c('h4',{staticClass:"text-start"},[_vm._v(" "+_vm._s(item.product.info)+" ")]):_vm._e()]),(item.paidQuantity < item.totalQuantity)?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"mouse-pointer",attrs:{"src":require("@/assets/credit-card.png"),"max-width":"3rem","contain":""}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('order.paidForFrom', { paidQuantity: item.paidQuantity, totalQuantity: item.totalQuantity, })))])])],1):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-checkbox',{staticClass:"big-checkbox",attrs:{"color":"success"},on:{"change":function($event){return _vm.onChangeStatus(item)}},model:{value:(item.isReady),callback:function ($$v) {_vm.$set(item, "isReady", $$v)},expression:"item.isReady"}})],1)]}}])}),(_vm.openInfoDialog)?_c('product-info-dialog',{attrs:{"productData":_vm.chosen},model:{value:(_vm.openInfoDialog),callback:function ($$v) {_vm.openInfoDialog=$$v},expression:"openInfoDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }