var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-card-title',{staticClass:"d-flex align-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10","sm":"11"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","icon":"","label":_vm.$t('generics.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"2","sm":"1"}},[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-refresh')},on:{"click":_vm.loadData}})],1)],1)],1),_c('v-data-table',{staticClass:"app-table",attrs:{"headers":_vm.headers,"items":_vm.orders,"search":_vm.search,"loading":_vm.loading,"items-per-page":20,"footer-props":{ 'items-per-page-options': [100, 30, 50, -1] },"sort-by":"delivery"},on:{"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"item.shipping",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.shipping))])]}},{key:"item.delivery",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.delivery.adjustedStartHour))])]}},{key:"item.clientName",fn:function(ref){
var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(item.client && item.client.fullname))])]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('span',[_vm._v(_vm._s(item.menu.products .map( function (ref) {
	var title = ref.product.title;
	var quantity = ref.quantity;

	return (quantity + " " + title);
} ) .join(', ')))])]),_c('v-col',{attrs:{"cols":"2"}},[(_vm.checkThereAreNotes(item))?_c('v-icon',[_vm._v("mdi-information")]):_vm._e()],1)],1)]}}])}),_c('order-description',{attrs:{"order":_vm.chosen,"type":-1},model:{value:(_vm.orderDescriptionDialogOpen),callback:function ($$v) {_vm.orderDescriptionDialogOpen=$$v},expression:"orderDescriptionDialogOpen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }