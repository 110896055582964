var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isFilterDefaultValueLoaded)?_c('filter-app-bar',{attrs:{"bin":"","bin-model":_vm.bin,"showBtn":true,"defaultStart":_vm.start,"defaultEnd":_vm.end,"defaultIsDeliveryDateValue":_vm.isDeliveryDate,"defaultFilterObj":_vm.defaultFilter,"showSwitch":true,"isBidsPage":true},on:{"update:binModel":function($event){_vm.bin = !_vm.bin},"handleClick":_vm.createBid,"updateStart":_vm.updateStart,"updateEnd":_vm.updateEnd,"updateDeliveryDate":_vm.updateDeliveryDate},model:{value:(_vm.showDateFilter),callback:function ($$v) {_vm.showDateFilter=$$v},expression:"showDateFilter"}}):_vm._e(),(_vm.isFilterDefaultValueLoaded)?_c('v-container',[(_vm.showDateFilter)?_c('h2',{staticClass:"text-center text-h5"},[_vm._v(" "+_vm._s(!_vm.end ? _vm.dayBySlash(_vm.start) : ((_vm.dayBySlash(_vm.end)) + " - " + (_vm.dayBySlash(_vm.start))))+" ")]):_vm._e(),_c('h1',{staticClass:"text-center mb-5 mt-3"},[_vm._v(" "+_vm._s(_vm.$tc('bid.index', 2))+" "+_vm._s(_vm.bin ? ("- " + (_vm.$t('generics.bin'))) : null)+" ")]),(_vm.bids.length)?_c('v-card',{staticClass:"pa-0",attrs:{"id":"orders-table","rounded":"lg"}},[_c('v-card-title',{staticClass:"d-flex align-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","icon":"","label":_vm.$t('generics.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[(_vm.bids.length)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.bids,"loading":_vm.loading,"search":_vm.search,"footer-props":{ 'items-per-page-options': [100, 30, 50, -1] },"id":"orders-table","sort-by":"delivery.day"},scopedSlots:_vm._u([{key:"item.delivery.day",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.delivery.day)))])]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [(_vm.bin)?_c('span',[_vm._v(_vm._s(item.orderNum || item.orderNumOfBid || ''))]):_c('span',[_vm._v(_vm._s(item.sum)+" ₪")])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"10px"}},[(!_vm.bin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"21","disabled":item.approved,"color":"black"},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-briefcase-edit ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('bid.edit')))])]):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openPdf(item)}}},[_c('v-icon',{attrs:{"size":"21","color":"black"}},[_vm._v(" mdi-download ")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"21","color":_vm.bin ? 'green' : 'red'},on:{"click":function($event){_vm.bin ? _vm.restoreItem(item) : _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.bin ? 'mdi-delete-restore' : 'mdi-trash-can-outline')+" ")])]}}],null,true)},[(_vm.bin)?_c('span',[_vm._v(_vm._s(_vm.$t('generics.restoreFromBin')))]):_c('span',[_vm._v(_vm._s(_vm.$t('bid.delete')))])]),(item.order)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{staticClass:"center-all",on:{"click":function($event){return _vm.editItem(item.order, true)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"size":"21"}},'v-icon',attrs,false),on),[_vm._v(" mdi-clipboard-check-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('bid.approved')))])]):_vm._e(),(_vm.bin && item.orderNum)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-trash-can-outline")]):_vm._e()],1)]}}],null,false,947390072)}):_vm._e()],1)],1):_c('div',{staticClass:"center-all mt-15"},[_c('h3',[_vm._v(_vm._s(_vm.$t('bid.noBids')))])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }