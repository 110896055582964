<template>
  <section>
    <v-card-title class="d-flex align-center">
      <v-row>
        <v-col cols="10" sm="11">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            icon
            :label="$t('generics.search')"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="2" sm="1">
          <v-icon color="primary" @click="loadData" v-text="'mdi-refresh'" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="productsByTime"
      :loading="loading"
      :items-per-page="20"
      class="app-table"
      :search="search"
      :footer-props="{ 'items-per-page-options': [100, 30, 50, -1] }"
      @click:row="openInfo"
    >
      <template v-slot:item.delivery.adjustedStartHour="{ item }">
        <h3 style="white-space: nowrap">
          {{ item.delivery.adjustedStartHour }}
        </h3>
      </template>
      <template v-slot:item.image="{ item }">
        <div>
          <image-show :src="`/uploads/${item.product.image}`" />
        </div>
      </template>
      <template v-slot:item.product.title="{ item }">
        <v-row class="fill-height align-center">
          <h3>{{ item.product.title }}</h3>
        </v-row>
      </template>
      <template v-slot:item.quantity="{ item }">
        <h3>{{ item.quantity }}</h3>
      </template>

      <template v-slot:item.product.info="{ item }">
        <v-row align="center" class="secondary--text">
          <v-col class="text-center">
            <h4 v-if="item.product.info" class="text-start">
              {{ item.product.info }}
            </h4>
          </v-col>
          <v-col
            v-if="item.paidQuantity < item.totalQuantity"
            cols="6"
            class="d-flex"
          >
            <!-- <v-img src="@/assets/meal.png" max-width="3rem" contain class="ml-3" /> -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  src="@/assets/credit-card.png"
                  max-width="3rem"
                  class="mouse-pointer"
                  contain
                  v-on="on"
                  v-bind="attrs"
                />
              </template>
              <span>{{
                $t('order.paidForFrom', {
                  paidQuantity: item.paidQuantity,
                  totalQuantity: item.totalQuantity,
                })
              }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.status="{ item }">
        <span>
          <v-checkbox
            class="big-checkbox"
            color="success"
            v-model="item.isReady"
            @change="onChangeStatus(item)"
          />
        </span>
      </template>
    </v-data-table>
    <product-info-dialog
      v-if="openInfoDialog"
      v-model="openInfoDialog"
      :productData="chosen"
    />
  </section>
</template>

<script>
// import AllProductOrders from '@/components/dialogs/AllProductOrders.vue'
import genericsServices from '../../services/generics'
import ImageShow from '../dialogs/ImageShow.vue'
import ProductInfoDialog from '@/components/dialogs/ProductInfoDialog.vue'
export default {
  name: 'kitchen-department-by-hours',
  props: {
    department: { type: String },
    filters: { type: Object },
  },
  components: { ImageShow, ProductInfoDialog },
  data() {
    return {
      loading: false,
      search: '',
      headers: [
        { text: this.$t('order.num'), value: 'orderNum' },
        {
          text: this.$t('order.deliveryTime'),
          value: 'delivery.adjustedStartHour',
        },
        { text: this.$t('generics.image'), value: 'image' },
        {
          text: this.$tc('generics.item'),
          value: 'product.title',
          cellClass: 'col-4',
        },
        { text: this.$t('generics.quantity'), value: 'quantity' },
        {
          text: this.$t('generics.notes'),
          value: 'product.info',
          cellClass: 'col-2',
        },
        { text: this.$tc('client.index'), value: 'client.fullname' },
        { text: this.$t('generics.status'), value: 'status' },
      ],
      openInfoDialog: false,
      chosen: null,
      clicked: null,
    }
  },
  computed: {
    productsByType() {
      return this.$store.getters.productsByType({
        start: this.filters.start,
        end: this.filters.end,
        isDeliveryDate: this.filters.isDeliveryDate,
        department: this.department,
        search: this.search,
      })
    },
    productsByTime() {
      let products = this.$store.getters.productsByTime.filter(item => {
        //checking department
        if (
          this.department &&
          item.product?.department?.key !== this.department
        ) {
          return false
        }

        return true
      })
      return products
      // return genericsServices.filterOrders(
      //   products,
      //   this.filters.start,
      //   this.filters.end,
      //   this.filters.isDeliveryDate
      // )
    },
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('order/index')
    },
    async onChangeStatus(item) {
      const { menuProductId: productId, orderId, isReady } = item
      await this.$store.dispatch('order/productStatus', {
        orderId,
        productId,
        isReady,
      })
    },
    openInfo(item, _, e) {
      if (e?.target?.className?.includes('v-input--selection-controls__ripple'))
        return
      const catalogTitle = item.product?.title
      const fountProductsByType = this.productsByType.find(
        item => item.products?.[0]?.title === catalogTitle
      )
      if (fountProductsByType) {
        this.chosen = fountProductsByType
        this.openInfoDialog = true
      }
    },
  },
}
</script>
