import i18n from '@/i18n'
const readyText = i18n.t('delivery.servingTypes.ready')
const sameDayText = i18n.t('delivery.servingTypes.same-day')
const tommorowText = i18n.t('delivery.servingTypes.tommorow')
const noSelectedText = i18n.t('delivery.servingTypes.no-mention')
export const serveTypesObject = {
  ready: readyText,
  'same-day': sameDayText,
  tommorow: tommorowText,
  noSelected: noSelectedText,
}
export const serveTypesList = [
  { key: 'ready', val: readyText },
  { key: 'same-day', val: sameDayText },
  { key: 'tommorow', val: tommorowText },
  { key: 'no-mention', val: noSelectedText },
]
