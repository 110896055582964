var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-card-title',{staticClass:"d-flex align-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10","sm":"11"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","icon":"","label":_vm.$t('generics.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"2","sm":"1"}},[_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s('mdi-refresh')},on:{"click":_vm.loadData}})],1)],1)],1),_c('v-data-table',{staticClass:"app-table",attrs:{"headers":_vm.headers,"items":_vm.productsByType,"loading":_vm.loading,"items-per-page":20,"footer-props":{ 'items-per-page-options': [100, 30, 50, -1] }},on:{"click:row":_vm.openInfo},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('image-show',{attrs:{"src":("/uploads/" + (item.image))}})]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(item.title))])]}},{key:"item.totalQuantity",fn:function(ref){
var item = ref.item;
return [_c('h3',[_vm._v(_vm._s(item.totalQuantity))])]}},{key:"item.infoNotes",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"secondary--text",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[(
              item.infoNotes.filter(
                function (infoNote) { return infoNote.info || infoNote.internalNotes || infoNote.menuInfo; }
              ).length
            )?_c('div',{staticClass:"link red--text"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('generics.numOfNotes', { num: _vm.countInfoNotes(item.infoNotes), }))+" ")])]):_c('h4',{staticClass:"grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t('generics.noNotes'))+" ")])])],1)]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-checkbox',{staticClass:"big-checkbox ms-n4",attrs:{"color":"success"},on:{"change":function($event){return _vm.onChangeStatus(item)}},model:{value:(item.isReady),callback:function ($$v) {_vm.$set(item, "isReady", $$v)},expression:"item.isReady"}})]}}])}),(_vm.openInfoDialog)?_c('product-info-dialog',{attrs:{"productData":_vm.chosen},model:{value:(_vm.openInfoDialog),callback:function ($$v) {_vm.openInfoDialog=$$v},expression:"openInfoDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }