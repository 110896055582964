var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('my-dialog',{attrs:{"title":_vm.productData.title,"persistent":false},scopedSlots:_vm._u([(_vm.productData)?{key:"content",fn:function(){return [_c('v-data-table',{staticClass:"app-table",attrs:{"headers":_vm.headers,"items":_vm.productData.infoNotes,"items-per-page":50,"footer-props":{ 'items-per-page-options': [100, 30, 50, -1] },"item-class":_vm.itemRowBackground},on:{"click:row":_vm.editOrder},scopedSlots:_vm._u([{key:"item.day",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.dayjs(item.day).format('DD-MM-YYYY')))])]}},{key:"item.serveInfo",fn:function(ref){
var serveInfo = ref.item.serveInfo;
return [(serveInfo)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(("delivery.servingTypes." + serveInfo))))]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-checkbox',{staticClass:"big-checkbox",attrs:{"color":"primary"},on:{"change":function($event){return _vm.onChangeStatus(item, index)}},model:{value:(item.isReady),callback:function ($$v) {_vm.$set(item, "isReady", $$v)},expression:"item.isReady"}})]}}],null,false,229365317)})]},proxy:true}:null],null,true),model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}}),(_vm.openDialog)?_c('order-dialog',{attrs:{"orderID":_vm.chosenOrder,"type":-1},model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }